import { VisibilityOff } from '@mui/icons-material';
import { MenuItem, Stack, Typography } from '@mui/material';
import MoreMenu from 'components/MoreMenu';
import { useUpdateListMatrixSettings } from './shared/useUpdateListMatrixSettings';

export const REACTIONS_HEADER_WIDTH = 120;

export const ReactionsColumnHeader = ({
  isReadonly,
}: {
  isReadonly: boolean;
}) => {
  const toggleListMatrixSettings = useUpdateListMatrixSettings();

  return (
    <Stack
      direction='row'
      data-testid='reactions-column-header'
      width={REACTIONS_HEADER_WIDTH}
    >
      <Stack justifyContent='center'>
        <Typography variant='body2' fontWeight={600}>
          Reactions
        </Typography>
      </Stack>
      {!isReadonly && (
        <MoreMenu>
          <MenuItem
            onClick={() => {
              toggleListMatrixSettings({
                reactions_column_enabled: false,
              });
            }}
          >
            <Stack direction='row' spacing={0.5}>
              <Stack justifyContent='center'>
                <VisibilityOff fontSize='small' />
              </Stack>
              <Typography variant='body2'>Hide</Typography>
            </Stack>
          </MenuItem>
        </MoreMenu>
      )}
    </Stack>
  );
};
