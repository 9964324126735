import { captureException, captureMessage } from '@sentry/react';
import {
  EnumTableLeadMaturityEnum,
  useCreateProjectServiceMutation,
} from 'apollo/generated/sdkInnovationManager';
import useAuth from 'hooks/useAuth';
import { useInsertProjectSourceTag } from 'hooks/useInsertProjectSourceTag';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useNavigate } from 'react-router';
import { buildProjectDetailsPath } from 'routes/paths';

export type CreateLeadSource =
  | 'Startup profile'
  | 'Search'
  | 'Manual Creation'
  | 'Link List to Lead Button'
  | 'New Challenge'
  | 'Leads assistant'
  | 'Leads Kanban List'
  | 'Leads Kanban Card';

export const DEFAULT_LEAD_TITLE_PREFIX = 'New Lead by';
export const useCreateLead = (skipNavigate: boolean = false) => {
  const [createProject, { loading }] = useCreateProjectServiceMutation();
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { handleInsertTag } = useInsertProjectSourceTag();

  const handleCreateLead = async ({
    title,
    leadMaturityLevel,
    painPointOwnerId,
    source = 'Manual Creation',
    sourceTagIds,
  }: {
    title?: string;
    leadMaturityLevel?: EnumTableLeadMaturityEnum | null;
    painPointOwnerId?: number;
    source: CreateLeadSource;
    sourceTagIds?: number[];
  }) => {
    try {
      let insertedTags;

      if (sourceTagIds?.length) {
        insertedTags = sourceTagIds;
      } else {
        const sourceTagId = await handleInsertTag(source);

        if (sourceTagId) {
          insertedTags = [sourceTagId];
        }
      }

      const { data } = await createProject({
        variables: {
          object: {
            title:
              title ||
              `${DEFAULT_LEAD_TITLE_PREFIX} ${currentUser.displayName}`,
            stage: 'scoping',
            source: 'self_created',
            type_of_solution: 'unknown',
            lead_maturity: leadMaturityLevel,
            pain_point_owner_id: painPointOwnerId,
            source_tag_ids: insertedTags,
          },
        },
      });

      const projectId = data?.create_project?.project?.id;

      if (projectId) {
        captureAnalyticsEvent('Project Lead Created', {
          projectId,
          source,
        });

        if (skipNavigate) {
          return { project: data.create_project!.project };
        }

        navigate(buildProjectDetailsPath({ id: projectId, stage: 'scoping' }));
      } else {
        captureMessage('Failed to create new lead');
        enqueueSnackbar('Failed to create new lead', { variant: 'error' });
      }
    } catch (error) {
      console.log('Failed to create new lead', error);
      captureException(error);
      enqueueSnackbar('Failed to create new lead', { variant: 'error' });
    }
  };

  return {
    handleCreateLead,
    loading,
  };
};
